var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card m-b-10",on:{"click":_vm.goToBroadcast}},[_c('div',{staticClass:"competition-preview w-100 p-relative",class:{
            'bg-size-auto': !_vm.competition.live_cover,
            'bg-size-cover': _vm.competition.live_cover
         },style:({
            backgroundImage: `url(${_vm.bgImg})`
        })},[(_vm.showTag)?_c('i',{staticClass:"tag",style:({backgroundImage: `url(${_vm.tagImg})`})}):_vm._e()]),_c('div',{staticClass:"text-info p-relative p-l-5 p-r-5 flex align-center w-100"},[_c('div',{staticClass:"avatar bg-size-cover bg-no-repeat bg-center border-50",style:({
                backgroundImage: `url(${_vm.logo})`
            })}),_c('div',{staticClass:"name-box p-l-5"},[_c('div',{staticClass:"competition-name text-white font-12 flex justify-between align-center font-regular m-b-5"},[_c('span',{staticClass:"d-inline-block font-500 font-12 font-medium text-ellipsis"},[_vm._v(_vm._s(_vm.competition.room_title))])]),_c('div',{staticClass:"host-info text-white text-ellipsis font-12 font-regular flex align-center"},[_c('span',{staticClass:"text-ellipsis"},[_vm._v("主播："+_vm._s(_vm.competition.nick || _vm.competition.anchor_name))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }